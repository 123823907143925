import React, { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { userService, roleService } from "/app/src/services";
import Controls from "./controls";
import Roles from "../roles";
import { Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import Filter from "./filter";
import Status from "./status";
import { useTranslation } from "react-i18next";
import { User, Role as RoleType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery } from "@tanstack/react-query";

import { buildParams } from "/app/src/helpers";
import { useAuthState } from "/app/src/contexts/authentication";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

type query = {
  limit: number;
  page: number;
  status?: string;
  roleId?: number | string;
  countOnly?: boolean;
  orderby?: string;
};

export default function UserList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authType } = useAuthState();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sort, setSort] = useSortUpgrade();
  const [selectedRole, setSelectedRole] = useState<string | number>("all");
  const [selectedStatus, setSelectedStatus] = useState("all");

  const tableHelper = createColumnHelper<User>();
  const columns = useMemo(
    () => [
      tableHelper.accessor("username", {
        id: "username",
        cell: (info) => info.getValue(),
        header: t("translation:username"),
      }),
      tableHelper.accessor("email", {
        id: "email",
        cell: (info) => info.getValue(),
        header: t("translation:email"),
      }),
      tableHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          return <p>{Status(info.getValue())}</p>;
        },
        header: t("translation:status"),
      }),
      tableHelper.accessor("type", {
        id: "type",
        cell: (info) => {
          return <p>{info.getValue()}</p>;
        },
        header: t("translation:type"),
      }),
      tableHelper.accessor("roleName", {
        id: "roleName",
        cell: (info) => {
          return <Tag className="userGroup">{info.getValue()}</Tag>;
        },
        header: t("translation:role"),
      }),
    ],
    [tableHelper, t],
  );

  /**
   * Function to navigate to user details page
   */
  const goToUser = useCallback(
    (userId: number) => {
      navigate(`/users/${userId}`);
    },
    [navigate],
  );

  //Get roles
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return roleService.getAll();
    },
    initialData: { roles: [] },
    select: (data: { roles: RoleType[] }) => {
      return data.roles;
    },
  });
  //Get number of users
  const { data: usersCount } = useQuery({
    queryKey: ["usersCount", selectedRole, selectedStatus],
    queryFn: () => {
      const query = {};
      if (selectedRole !== "all") {
        query["roleId"] = selectedRole;
      }
      if (selectedStatus !== "all") {
        query["status"] = selectedStatus;
      }
      return userService.getCount(buildParams(query));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //Get users
  const { data: users, isFetching } = useQuery({
    queryKey: ["users", page, pageSize, selectedRole, selectedStatus, sort],
    queryFn: () => {
      const query: query = {
        limit: pageSize,
        page,
        orderby: getOrderByQuery(sort),
      };
      if (selectedRole !== "all") {
        query["roleId"] = selectedRole;
      }
      if (selectedStatus !== "all") {
        query["status"] = selectedStatus;
      }
      return userService.getAll(buildParams(query));
    },
    initialData: { users: [] },
    select: (data: { users: User[] }) => {
      return data.users;
    },
  });

  return (
    <div className="users">
      <Helmet>
        <title>{t("translation:users")} - ItemPath</title>
      </Helmet>
      {authType === "basic" && <Controls />}
      <Row gutter={20}>
        <Col span={6}>
          <Filter
            roles={roles}
            setRole={setSelectedRole}
            setStatus={setSelectedStatus}
          />
          <Roles roles={roles} />
        </Col>
        <Col span={18}>
          <Table
            loading={isFetching}
            rows={users}
            tableColumns={columns}
            length={usersCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            enableRowSelection
            rowClicked={goToUser}
            emptyText="No Users Found"
          />
        </Col>
      </Row>
    </div>
  );
}
