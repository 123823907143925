import { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { integrationService } from "/app/src/services";
import { Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import Filters from "./filters";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { Integration, App } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import getOrderByQuery from "/app/src/helpers/table";
import { Theme as ThemeType } from "/app/src/types";
import DeleteButtonCell from "/app/src/components/generic/tables/deleteButtonCell";
import StatusIcon from "/app/src/components/generic/components/statusIcon";

// Custom component to render Themes
const Theme = ({ value }: { value: string }) => {
  if (value) {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return <Tag className="theme">{value}</Tag>;
  } else {
    return null;
  }
};

export default function IntegrationsList({
  app,
  themes,
  types = [],
  includeStatus = false,
  includeType = true,
}: {
  app: App;
  themes?: ThemeType[];
  types?: ThemeType[];
  includeStatus?: boolean;
  includeType?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("all");
  const [selectedType, setSelectedType] = useState("all");
  const columnHelper = createColumnHelper<Integration>();

  const { data: integrationsCount, refetch: refetchCount } = useQuery({
    queryKey: [
      "integrations",
      searchString,
      app.id,
      selectedTheme,
      selectedType,
    ],
    queryFn: () => {
      return integrationService.getCount(
        buildParams({
          appId: app.id,
          search: searchString,
          theme: selectedTheme,
          number: selectedType,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const {
    data: integrations,
    isFetching,
    refetch: refetchIntegrations,
  } = useQuery({
    queryKey: [
      "integrations",
      page,
      pageSize,
      searchString,
      selectedTheme,
      selectedType,
      app.id,
      sort,
    ],
    queryFn: () => {
      return integrationService.getAll(
        buildParams({
          limit: pageSize,
          page,
          theme: selectedTheme,
          number: selectedType,
          search: searchString,
          appId: app.id,
          orderBy: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { integrations: [] },
    select: (data: { integrations: Integration[] }) => {
      return data.integrations;
    },
  });

  const refreshTable = useCallback(() => {
    refetchCount();
    refetchIntegrations();
  }, [refetchCount, refetchIntegrations]);

  const deleteIntegration = useCallback((id: number) => {
    return integrationService.deleteSingle(id);
  }, []);

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (info) => (
          <DeleteButtonCell
            id={info.getValue()}
            refresh={refreshTable}
            deleteFn={deleteIntegration}
          />
        ),
        header: () => "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
    ];
    if (includeType) {
      cols.splice(
        cols.length - 1,
        0,
        columnHelper.accessor("number", {
          id: "number",
          cell: (info) => info.getValue(),
          header: () => t("translation:type"),
        }),
      );
    }
    if (themes) {
      cols.splice(
        cols.length - 1,
        0,
        columnHelper.accessor("baseTable", {
          id: "baseTable",
          cell: (info) => <Theme value={info.getValue()} />,
          header: () => t("translation:theme"),
        }),
      );
    }
    if (includeStatus) {
      cols.splice(
        cols.length - 1,
        0,
        columnHelper.accessor("status".toString(), {
          id: "status",
          cell: (info) => {
            const value = Number(info.getValue());
            return <StatusIcon status={value} />;
          },
          header: () => t("translation:status"),
        }),
      );
    }
    return cols;
  }, [
    columnHelper,
    deleteIntegration,
    includeStatus,
    includeType,
    refreshTable,
    t,
    themes,
  ]);

  /**
   *
   * @param integrationId number | string
   * @returns void
   * Navigates to the integration page
   */
  const goToIntegration = useCallback(
    (integrationId: number | string) => {
      navigate(`/apps/${app.id}/integrations/${integrationId}`);
    },
    [app.id, navigate],
  );

  return (
    <div>
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>
      <Row gutter={20}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          lg={{ span: 6, order: 1 }}
        >
          <Filters
            themes={themes}
            setSearchString={setSearchString}
            setSelectedTheme={setSelectedTheme}
            setSelectedType={setSelectedType}
            types={types}
          />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 2 }}
        >
          <Table
            loading={isFetching}
            rows={integrations}
            tableColumns={columns}
            length={integrationsCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            enableRowSelection
            rowClicked={goToIntegration}
            emptyText={t("translation:no_integrations_found")}
          />
        </Col>
      </Row>
    </div>
  );
}
