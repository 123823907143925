import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { reportService, userReportService } from "/app/src/services";
import NewReportForm from "./newReportForm";
import Filters from "./filters";
import Controls from "./controls";
import { Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Report as ReportType } from "/app/src/models";
import { themes as themesList } from "/app/src/constants/themes";
import DuplicateReport from "./duplicateReport";
import { jobAppCheck } from "/app/src/helpers/themes";
import { buildParams } from "/app/src/helpers/params";
import { useAuthState } from "/app/src/contexts/authentication";
import { Sort } from "/app/src/types";
import FavouriteStar from "../generic/components/favouriteStar";
import { useContextForPermission } from "../../hooks/usePermission";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import DeleteButtonCell from "/app/src/components/generic/tables/deleteButtonCell";
import NextButton from "/app/src/components/NextUi/Button";
// Custom component to render Themes
const Theme = ({ value }: { value: string }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return <Tag className="theme">{value}</Tag>; // skipcq: JS-0394
};

/**
 * Component to display a list of reports, with filters, controls, and table
 */
export default function ReportList({
  hasPermission,
}: {
  hasPermission: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { canCreate, canDelete } = useContextForPermission("REPO");

  const [themes, setThemes] = useState([]);
  const [isAllReports, setIsAllReports] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("all");
  const [activeNew, setActiveNew] = useState(false);
  const [activeImportReport, setActiveImportReport] = useState(false);
  const invalidateReports = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["reports"] });
    queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
  }, [queryClient]);
  const toggleNew = useCallback(() => {
    setActiveNew(!activeNew);
  }, [activeNew]);
  const toggleImportReport = useCallback(() => {
    setActiveImportReport(!activeImportReport);
  }, [activeImportReport]);

  const goToReport = useCallback(
    (reportId: number) => {
      navigate(`/reports/${reportId}`);
    },
    [navigate],
  );
  const unFavouriteReport = useCallback(
    (reportId: number) => {
      userReportService.unfavouriteReport(user.id, reportId).then(() => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        if (!isAllReports) {
          queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
        }
      });
    },
    [user.id, queryClient, isAllReports],
  );
  useEffect(() => {
    jobAppCheck(themesList).then((response) => {
      setThemes(response);
    });
  }, []);
  const setAllReports = useCallback(() => {
    setIsAllReports(true);
  }, []);
  const setFavourites = useCallback(() => {
    setIsAllReports(false);
  }, []);

  const favouriteReport = useCallback(
    (reportId: number) => {
      userReportService.favouriteReport(user.id, reportId).then(() => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      });
    },
    [user.id, queryClient],
  );

  useEffect(() => {
    setPage(0);
  }, [searchString, selectedTheme, isAllReports]);

  /**
   * Function to get reports
   * @param size the page size
   * @param currentPage the current page
   * @param theme the selected report theme
   * @param search search string
   * @param sortBy column to sort by
   * @param allReports boolean to determine if all reports or favourited reports
   * @returns Promise<any>
   */
  const getReports = useCallback(
    (
      size: number,
      currentPage: number,
      theme: string,
      search: string,
      sortBy: Sort,
      allReports: boolean,
      isLocked: boolean,
    ) => {
      const getAllParams = buildParams({
        limit: size,
        page: currentPage,
        theme,
        search,
        orderby: getOrderByQuery(sortBy),
        roleId: user.roleId,
      });
      if (isLocked) {
        getAllParams.append("locked", "true");
      }
      if (allReports) {
        return reportService.getAll(getAllParams);
      }
      return userReportService.getFavouritedReports(user.id, getAllParams);
    },
    [user.id, user.roleId],
  );

  /**
   * The function to get the count of reports
   * @param search search string
   * @param theme report theme to filter by
   * @param allReports boolean to determine if all reports or favourited reports
   * @returns Promise<any>
   */
  const getReportsCount = useCallback(
    (search: string, theme: string, allReports: boolean, isLocked) => {
      const getCountParams = buildParams({
        countOnly: true,
        search,
        theme,
        roleId: user.roleId,
      });
      if (isLocked) {
        getCountParams.append("locked", "true");
      }
      if (allReports) {
        return reportService.getCount(getCountParams);
      }
      return userReportService.getCount(user.id, getCountParams);
    },
    [user.id, user.roleId],
  );

  const getReportsQuery = useQuery({
    queryKey: [
      "reports",
      page,
      pageSize,
      searchString,
      selectedTheme,
      sort,
      isAllReports,
      hasPermission,
    ],
    queryFn: () =>
      getReports(
        pageSize,
        page,
        selectedTheme,
        searchString,
        sort,
        isAllReports,
        !hasPermission,
      ),
    keepPreviousData: true,
    staleTime: 10 * 1000,
    placeholderData: { reports: [] },
  });

  const getReportsCountQuery = useQuery({
    queryKey: [
      "reportsCount",
      searchString,
      selectedTheme,
      isAllReports,
      hasPermission,
    ],
    queryFn: () =>
      getReportsCount(
        searchString,
        selectedTheme,
        isAllReports,
        !hasPermission,
      ),
    keepPreviousData: true,
    staleTime: 10 * 1000,
    placeholderData: { count: 0 },
  });

  const deleteReport = useCallback((id: number) => {
    return reportService.deleteSingle(id);
  }, []);

  const columnHelper = createColumnHelper<ReportType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("favouritedUsers", {
        id: "isFavourited",
        cell: (info) => {
          const value = info.getValue();
          if (Array.isArray(value)) {
            return (
              <FavouriteStar
                isFavourite={value.includes(user.id)}
                objectId={info.row.original.id}
                favouriteFn={favouriteReport}
                unfavouriteFn={unFavouriteReport}
              />
            );
          }
          return null;
        },
        header: "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:report_name"),
      }),
      columnHelper.accessor("baseTable", {
        id: "baseTable",
        cell: (info) => <Theme value={info.getValue()} />,
        header: t("translation:theme"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:date"),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (info) => (
          <div style={{ display: "flex" }}>
            {canCreate && <DuplicateReport reportId={info.getValue()} />}
            {canDelete && (
              <DeleteButtonCell
                id={info.getValue()}
                refresh={invalidateReports}
                deleteFn={deleteReport}
              />
            )}
          </div>
        ),
        header: "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
    ],
    [
      canCreate,
      canDelete,
      columnHelper,
      favouriteReport,
      invalidateReports,
      t,
      unFavouriteReport,
      user.id,
      deleteReport,
    ],
  );

  return (
    <div className="reports">
      <Helmet>
        <title>{t("translation:reports")} - ItemPath</title>
      </Helmet>

      {canCreate && (
        <Controls
          toggleNew={toggleNew}
          activeNew={activeNew}
          goToReport={goToReport}
          toggleImportReport={toggleImportReport}
          activeImportReport={activeImportReport}
        />
      )}
      {activeNew ? <NewReportForm themes={themes} /> : ""}
      <Row gutter={20}>
        <Col span={5}>
          <Row>
            <Col span={12}>
              <NextButton
                size="md"
                color={isAllReports ? "primary" : "default"}
                variant="solid"
                onClick={setAllReports}
                fullWidth
              >
                {t("translation:all_reports")}
              </NextButton>
            </Col>
            <Col span={12}>
              <NextButton
                size="md"
                color={!isAllReports ? "primary" : "default"}
                variant="solid"
                onClick={setFavourites}
                fullWidth
              >
                {t("translation:favourites")}
              </NextButton>
            </Col>
          </Row>
          <Filters
            themes={themes}
            setSearchString={setSearchString}
            setSelectedTheme={setSelectedTheme}
          />
        </Col>
        <Col span={19}>
          <Table
            rows={getReportsQuery.data?.reports}
            loading={getReportsQuery?.isFetching || getReportsQuery?.isLoading}
            tableColumns={columns}
            length={getReportsCountQuery.data?.count}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            rowClicked={goToReport}
            emptyText={
              isAllReports
                ? t("translation:no_reports_found")
                : t("translation:no_favourite_reports")
            }
          />
        </Col>
      </Row>
    </div>
  );
}
