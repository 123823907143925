import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import { useCallback } from "react";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { LogoBuilder } from "../icons/itempathLogo";

interface LocationType {
  state: {
    from?: string;
  };
}

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as LocationType;

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const goBack = useCallback(() => {
    if (state?.from) {
      const lastSlashIndex = state.from.lastIndexOf("/");
      const newFrom = state.from.slice(0, lastSlashIndex);
      navigate(newFrom);
    } else {
      navigate("/dashboard");
    }
  }, [navigate, state?.from]);

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-214px)]">
      <div className="flex items-center justify-center px-[32px] py-[12px]">
        <LogoBuilder icon="ItemPathLogoWithText" theme="default" />
      </div>
      <div className="pt-xxlarge">
        <div className="text-neutral-charcoal font-semibold text-xlarge flex items-center justify-center ">
          {t("translation:404_page")}
        </div>
        <div className="text-[20px] flex flex-col items-center">
          <span>{t("translation:404_page_description")}</span>
          <span className="text-[#3B8BEA] text-[20px]">
            <ul className="list-disc">
              <li>
                <a
                  href="https://itempath.com/resource-central"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("translation:resource_center")}
                </a>
              </li>
              <li>
                <a
                  href="https://itempath.com/docs"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("translation:documentation")}
                </a>
              </li>
              <li>
                <a
                  href="https://itempath.com/courses"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("translation:courses")}
                </a>
              </li>
            </ul>
          </span>
          <div className="flex items-center">
            <NextButton
              onClick={goBack}
              className="rounded-xxsmall mr-small"
              variant="bordered"
            >
              <IconBuilder icon="ChevronLeft" size={20} />
              {t("translation:go_back")}
            </NextButton>
            <NextButton
              color="primary"
              className="rounded-xxsmall"
              onClick={goToDashboard}
            >
              {t("translation:go_to_dashboard")}
            </NextButton>
          </div>
        </div>
      </div>
    </div>
  );
}
